import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
import Layout from "../../components/3rd/layout";
import SEO from "../../components/seo";
import Wrapper from "../../components/wrapper";
import styles from "./index.module.scss";
import Hero from "../../components/3rd/hero";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="第三回BMS衆議院選" description="衆議院選挙を模した BMS チーム戦イベントです。各チーム（政党）はあらかじめ制作する BMS の方針（マニフェスト）を公開し、所属するメンバー（候補者）はマニフェストに沿った BMS を制作します。" meta={[{
      name: 'twitter:image',
      content: "https://bmsshuin.qstol.info/images/3rd/ogp.png"
    }, {
      name: 'og:image',
      content: "https://bmsshuin.qstol.info/images/3rd/ogp.png"
    }]} mdxType="SEO" />
    <Hero mdxType="Hero" />
    <Wrapper mdxType="Wrapper">
      <h2>{`主旨`}</h2>
      <p>{`衆議院選挙を模した BMS チーム戦イベントです。各チーム（政党）はあらかじめ制作する BMS の方針（マニフェスト）を公開し、所属するメンバー（候補者）はマニフェストに沿った BMS を制作します。`}</p>
      <p>{`インプレ（投票）により人気（民意）を問い、最も人気のあったチームは与党となり、与党チーム内で最も点数が高かった候補者は総理大臣になります。`}</p>
      <h2>{`開催スケジュール`}</h2>
      <dl className={styles.terms}>
  <dt>2020/12/01(火) - 12/31(木)</dt>
  <dd>参加表明</dd>
  <dt>2021/01/28(木) - 01/31(日)</dt>
  <dd>登録</dd>
  <dt>2021/02/03(水) - 02/28(日)</dt>
  <dd>インプレッション</dd>
      </dl>
      <h2>{`ルール`}</h2>
      <h3>{`参加表明`}</h3>
      <p>{`参加表明期間中は以下のことが可能です。本イベントに BMS を登録するためには、必ず参加表明期間内に立候補を行う必要があります。`}</p>
      <div className={styles.detail_box}>
        <h4>{`政党立ち上げ`}</h4>
        <p>{`任意のチーム名（政党名）でチーム（政党）を立ち上げることができます。政党立ち上げの際には、その政党の BMS の方針（マニフェスト）を公開する必要があります。当該チームから BMS を出す候補者は、政党のマニフェストに従って BMS を作らなければなりません。`}</p>
        <h4>{`立候補`}</h4>
        <p>{`すでにあるチーム（政党）からの参加を宣言することができます。また、新たに政党を立ち上げて 参加することもできます。どのチームにも属したくない場合は無所属での立候補も可能です。複数の政党から立候補することはできません。`}</p>
      </div>
      <p>{`※ チーム（政党）の乱立を防ぐため、立候補作者数および実際の BMS 登録者が 3 人に満たない政党は政党として認められず、 その政党の候補者は無所属として出馬することになります。`}</p>
      <p>{`※ サポートメンバーは立候補の必要はありません。立候補者 1 人につき 1 作品の BMS を提出していただきます。合作の場合も 1 人として立候補ください。`}</p>
      <h3>{`登録`}</h3>
      <p>{`登録する BMS は以下の条件を満たしている必要があります。`}</p>
      <ul>
        <li parentName="ul">{`BRS規定（ver.002）に準拠していること `}<a href='https://brs.bmssearch.net/BRS/002.html' target='_blank'><img src='https://brs.bmssearch.net/BRS/002.png' title='BRS ver.002 に準拠するBMSに限る' width='32' /></a></li>
        <li parentName="ul">{`一人一作品まで`}</li>
        <li parentName="ul">{`オリジナル作品であること`}</li>
        <li parentName="ul">{`所属チーム（政党）のマニフェストに則っていること（満たしていなくても失格の対象にはなりません）`}</li>
      </ul>
      <h3>{`インプレッション`}</h3>
      <p>{`投票の方法は以下の二種類あります。`}</p>
      <div className={styles.detail_box}>
        <h4>{`小選挙区インプレ`}</h4>
        <p>{`通常の BMS イベント同様、BMS にインプレをします。1 ～ 5 点でのインプレになります。自分の属する、もしくはサポート等何らかの形で参加している政党の BMS にはインプレしてはいけません。`}</p>
        <h4>{`比例代表インプレ`}</h4>
        <p>{`好きな政党にひとり一票まで投票することができます。自分の属する、もしくは何らかの形で参加している政党へは投票してはいけません。`}</p>
      </div>
      <p>{`※ BRS規定（ver.002）のインプレ基準に反するインプレは削除の対象となります。 `}<a href='https://brs.bmssearch.net/BRS/002.html' target='_blank'><img src='https://brs.bmssearch.net/BRS/002.png' title='BRS ver.002 に準拠するBMSに限る' width='32' /></a></p>
      <h3>{`得点計算`}</h3>
      <p>{`小選挙区 60議席 + 比例代表 60議席 の合計 120議席 を政党に振り分けます。`}<br parentName="p"></br>{`
`}{`最も大きい議席数を獲得した政党を「与党」とします。また、「与党」チームの中で最も合計点が高かった BMS, 作者を「内閣総理大臣」もしくは「首相」に指名します。
各選挙区の議席計算方法は以下の通りです。`}</p>
      <div className={styles.detail_box}>
        <h4>{`小選挙区インプレ`}</h4>
        <p>{`通常の BMS イベント同様、得点の合計を競います。上位 60人 を当選とし、所属する政党は当選者人数だけ議席を獲得します。同率順位は、平均点で比較します。`}</p>
        <h4>{`比例代表インプレ`}</h4>
        <p>{`各党の投票割合に応じて 60 議席を分配します。整数には直さず小数のまま計算します。`}</p>
      </div>
      <p>{`※ 獲得総議席数が同じだった場合は比例代表の獲得議席数が多いほうを与党とします。これも一致した場合、党の小選挙区合計得点が高いほうを与党とします。`}</p>
      <p>{`※ ルールの性質上、人数の多いチームが有利になります。 しかし、BMS 衆議院選では政権をとることよりも、自分たちの信念を貫き BMS 界に一石を投じていただきたい、という思いから、チーム人数の違いによる議席数の補正は行いません。 有権者の顔色ばかり伺うのではなく、国民にとってこんな BMS が必要なのだ、という強い思いの感じられる作品をお待ちしております。`}</p>
      <h2>{`連絡先`}</h2>
      <p>{`ご連絡等は Twitter 公式アカウント `}<a parentName="p" {...{
          "href": "https://twitter.com/BMS_Shuin"
        }}>{`@BMS_Shuin`}</a>{` 、もしくは主催 q/stol の Twitter アカウント `}<a parentName="p" {...{
          "href": "https://twitter.com/q_stol"
        }}>{`@q_stol`}</a>{` までお願いします。`}</p>
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      